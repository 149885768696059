<script setup lang="ts">
import ProductList from '~/components/plp/ProductList.vue';
import FilterBar from '~/components/filter-bar/index.vue';
import { useRoute, type RouteLocationNormalizedLoaded } from 'vue-router';
import { fetchBrandProducts, useProductsList } from '~/data/products';
import { useSeoData } from '~/data/entities';
import { useDebounceFn } from '@vueuse/core';
import PlpHeader from '~/components/plp/Header.vue';
import Description from '~/components/plp/Description.vue';
import { useNodesQuery } from '~/data/nodes';

import NodesBar from '~/components/plp/NodesBar.vue';
const { $cathodeClient } = useNuxtApp();
const { $eventClient } = useNuxtApp();

definePageMeta({
	layout: 'default',
});

const route = useRoute();
const handle = ref(route.params.handle as string);
const filters = ref<string>(route.fullPath.split('?')[1] || '');

const { storeType } = useGlobalStore();

const enabled = ref(true);
const { data: seo, suspense, error } = useSeoData(handle, enabled);
const disableSignReload = ref(false);
const toggleDisableSignReload = () => {
	disableSignReload.value = !disableSignReload.value;
};

provide('toggleDisableSignReload', toggleDisableSignReload);

watch(disableSignReload, (newValue) => {
	if (newValue) {
		enabled.value = false;
	} else {
		enabled.value = true;
	}
});

watch(seo, (seoData) => {
	if (!seoData) return;
	if (Object.keys(seoData || {}).length) {
		$eventClient.sendEvent('view-item-list', {
			id: seoData.id,
			title: seoData.title,
			productCount: seoData.subtitle,
		});
	}
});

onServerPrefetch(async () => {
	await suspense();
});

await suspense();
const {
	data,
	isLoading,
	hasNextPage,
	fetchNextPage,
	isFetchingNextPage,
	error: productListError,
} = useProductsList({
	queryKey: ['brands', handle, filters],
	queryFn: ({ pageParam: cursor }) => {
		return fetchBrandProducts(handle.value, cursor as string, filters.value);
	},
	enabledValue: enabled,
});

const debounce = useDebounceFn((route: RouteLocationNormalizedLoaded) => {
	filters.value = route.fullPath.split('?')[1] || '';
	handle.value = route.params.handle as string;
}, 200);

const isNodesQueryEnabled = ref(false);

const { data: nodesData } = useNodesQuery(
	'brands',
	handle,
	isNodesQueryEnabled,
);

watch(route, (route) => {
	if (!route.name!.toString().includes('brands')) {
		return;
	}
	debounce(route);
});

const plpNewUi = inject('plpNewUi') as Ref<boolean>;

onMounted(() => {
	if (!seo.value || error.value) {
		console.log('error', error.value, seo.value);
		throw createError({
			statusCode: 404,
			message: 'Page not found',
			data: {
				url: route.fullPath,
			},
			fatal: true,
		});
	}
	isNodesQueryEnabled.value = plpNewUi.value;
	$cathodeClient.queueEvent('page_load', {
		source: `${window.location.origin}${route.path}`,
		page: 'PLP',
		statusCode: 200,
		referrer: document.referrer,
		collection_handle: route.params.handle as string,
	});
});

watch(isLoading, (isLoading) => {
	if (!isLoading && productListError.value) {
		throw createError({
			statusCode: 404,
			message: 'Failed to load products',
			data: {
				url: route.fullPath,
			},
			fatal: true,
		});
	}
});
</script>
<template>
	<div>
		<PlpHeader :data="seo" />
		<Description
			v-if="!!seo?.description && storeType === STORE_TYPE.MARKETPLACE"
			:description="seo.description"
		/>
		<ClientOnly>
			<div
				v-if="plpNewUi && nodesData && nodesData.length > 0"
				class="flex flex-col gap-3xl border-b border-gray-200 py-3xl"
			>
				<!-- TODO: remove this if not used -->
				<!-- <SeoHeader :seo="seo" :showing-banner="visible && !!shippingConfig" /> -->
				<NodesBar :feed-nodes="nodesData" />
			</div>
			<FilterBar :handle="handle" />

			<ProductList
				:infinite-loader="true"
				:fetch-next-page="fetchNextPage"
				:is-loading="isLoading"
				:data="data"
				:has-next-page="hasNextPage"
				:is-fetching-next-page="isFetchingNextPage"
			/>
			<template #fallback>
				<FilterbarSkeleton />
				<ProductListSkeleton />
			</template>
		</ClientOnly>
	</div>
</template>
